<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview transparent">
                            <h4 class="green mb-3 text-center">Markets</h4>
                            <ul class="traderGDP minAuto m-0" v-if="marketData.length">
                                <li class="justify-content-between text-center" v-for="item,index in marketData"
                                    :key="index">
                                    <span class="f-16">
                                        <span class="d-flex align-items-center">
                                            <vue-feather class="sm-16 mr-1 green" type="arrow-up"></vue-feather>{{(index
                                                                                        < 3) ? (item.HistoricalDataSymbol) ? item.HistoricalDataSymbol : '' :
                                            ecoJSon[index]}}</span>
                                                <!-- <span class="green d-block f-14">+179.95</span> -->
                                        </span>
                                        <span class="f-16">{{item.LatestValue}}
                                            <!-- <span class="green d-block f-14">1.08%</span>-->
                                        </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap">
                            <economic-mainnavbar></economic-mainnavbar>
                            <div class="flexAuto">
                                <economic-map v-show="Object.keys(store.economicMarketHistory).length > 0">
                                </economic-map>
                                <div class="row justify-content-center" v-if="marketData.length">
                                    <div class="col-12 col-md-4 mb-3 mb-md-0" v-if="marketData[0]">
                                        <div class="niftyTrack d-flex align-items-center">
                                            <h6 class="mb-0 mr-3 flex-none uppercase">
                                                {{marketData[0].HistoricalDataSymbol}}</h6>
                                            <ul class="chartGT niftyBlock d-flex align-items-center m-0">
                                                <!-- <li>
                                                <span class="progress custom overflow-visible">
                                                    <div class="progress-bar redBG w-100" style="width: 100%; height: 5px;">
                                                        <span class="triangle red">-0.82%</span> <span class="triangle anglr red"><i class="fa fa-caret-down mr-2" aria-hidden="true"></i></span>
                                                        <span class="bottom position-absolute red f-13">D</span>
                                                    </div>
                                                </span>
                                            </li> -->
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar redBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle red">2.09%</span> <span
                                                                class="triangle anglr red"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute red f-13">W</span>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar greenBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle green">6.02%</span> <span
                                                                class="triangle anglr green"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute green f-13">M</span>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar greenBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle green">20.46%</span> <span
                                                                class="triangle anglr green"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute green f-13">Y</span>
                                                        </div>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4" v-if="marketData[1]">
                                        <div class="niftyTrack d-flex align-items-center">
                                            <h6 class="mb-0 mr-3 flex-none uppercase">
                                                {{marketData[1].HistoricalDataSymbol}}</h6>
                                            <ul class="chartGT niftyBlock d-flex align-items-center m-0">
                                                <!-- <li>
                                                <span class="progress custom overflow-visible">
                                                    <div class="progress-bar redBG w-100" style="width: 100%; height: 5px;">
                                                        <span class="triangle red">1.11%</span> <span class="triangle anglr red"><i class="fa fa-caret-down mr-2" aria-hidden="true"></i></span>
                                                        <span class="bottom position-absolute red f-13">D</span>
                                                    </div>
                                                </span>
                                            </li> -->
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar redBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle red">2.14%</span> <span
                                                                class="triangle anglr red"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute red f-13">W</span>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar greenBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle green">7.17%</span> <span
                                                                class="triangle anglr green"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute green f-13">M</span>
                                                        </div>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="progress custom overflow-visible">
                                                        <div class="progress-bar greenBG w-100"
                                                            style="width: 100%; height: 5px;">
                                                            <span class="triangle green">18.84%</span> <span
                                                                class="triangle anglr green"><i
                                                                    class="fa fa-caret-down mr-2"
                                                                    aria-hidden="true"></i></span>
                                                            <span class="bottom position-absolute green f-13">Y</span>
                                                        </div>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            marketData: [],
            ecoJSon: {
                3: 'Inflation Rate',
                4: 'Credit Rating',
                5: 'GDP Annual Growth Rate',
                6: 'Business Confidence'
            }
        }
    },
    components: {
        bannerSlider: bannerslider,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
        economicMainnavbar: economicmainnavbar,

    },
    computed: {

    },
    methods: {
        getCountryWiseData(country) {
            this.marketData = [];
            if (Object.keys(this.store.economicOverview).length > 0 && this.$route.query.country) {
                for (let key in this.store.economicOverview) {
                    if (this.store.economicOverview[key].length > 0) {
                        let data = this.store.economicOverview[key].filter(val => val.Country == country)[0]
                        this.marketData.push(data)
                    }
                }
            } else {
                this.marketData = []
            }
        },
        getEconomicOverview() {
            let formData = {
                indicator: [
                    "Stock Market",
                    "Currency",
                    "Government Bond 10Y",
                    "Inflation Rate",
                    "Credit Rating",
                    "GDP Annual Growth Rate",
                    "Business Confidence",
                ],
            };
            this.store
                .callEconomicOverview(formData, false)
                .then((response) => {
                    this.getCountryWiseData(this.$route.query.country)
                })
                .catch((err) => console.log(err.message));
        },
        callCountryEconomyMarketData(country) {
            let formData = {
                country: [country],
                group: "Markets",
                indicator: ["Stock Market", "Currency"],
                "year": 0,
                "filters": { "type": "previous_data" }
            };
            this.store
                .getCountryEconomyMarketHistory(formData, true)
                .then((response) => { })
                .catch((err) => console.log(err.message));
        },
    },
    mounted() {
        if (this.$route.query.country) {
            this.callCountryEconomyMarketData(this.$route.query.country);
        }
        this.getEconomicOverview()
    }
}
</script>
